import React from 'react';
import { graphql } from 'gatsby';
import { formatDate } from '@assets/scripts/helpers/formatDate';
import Link from '@components/atoms/LinkPage';

// data
import i18n from '@data/i18n';

// components
import Layout from '@src/layouts';
import CardArticle from '@components/molecules/cards/CardArticle';
import HeroPage from '@components/molecules/heros/HeroPage';

// assets
import '@assets/scss/pages/page-blog.scss';
import imgEight from '@assets/images/eight.svg';

// --------------------------------
// #region Data
// --------------------------------

const pageClass = 'page-blog';

// --------------------------------
// #endregion
// --------------------------------

const BlogPage = ({
	pageContext: {
		language,
		blogPath,
		currentPage,
		numPages,
		hasPreviousPage,
		hasNextPage,
	},
	data,
	location,
}) => {
	const posts = data.wpgraphql.posts.edges;

	const languageSlug = language.toLowerCase();

	const footerRelatedLinks =
		languageSlug === 'fr'
			? [
					{
						title: `L'Agence`,
						url: '/fr/agence/',
					},
					{ title: 'Projets', url: '/fr/projets/' },
			  ]
			: [
					{
						title: 'Agency',
						url: '/en/agency/',
					},
					{ title: 'Work', url: '/en/projects/' },
			  ];

	return (
		<Layout
			language={languageSlug}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			location={location}
			translations={
				languageSlug === 'fr'
					? [{ uri: '/en/blog/', lang: 'en' }]
					: [{ uri: '/fr/blog/', lang: 'fr' }]
			}
			title={
				languageSlug === 'fr'
					? `Le blog de l’agence, notre quotidien d’agence digitale Suisse`
					: `Blog - Superhuit`
			}
		>
			<HeroPage image={imgEight} imageY={52} title="Blog" />
			<section
				className="grid blog-list"
				aria-label="Blog List"
				data-animation-page
			>
				{posts.map(
					({
						node: {
							id,
							title,
							uri,
							featuredImage,
							excerpt,
							author,
							date,
							categories,
						},
					}) => {
						const excerptTxt = excerpt.replace(/(<([^>]+)>)/gi, '');

						const categoryName =
							categories?.nodes?.[0]?.name ?? null;

						const featuredImageSources = [
							{
								...featuredImage.mobile.childImageSharp?.fluid,
								media: `(max-width: 767px)`,
							},
							{
								...featuredImage.desktop.childImageSharp?.fluid,
								media: `(min-width: 768px)`,
							},
						];

						const isGifImage =
							featuredImage.mimeType === 'image/gif';

						return (
							<CardArticle
								key={id}
								className="col-default"
								link={{
									title: i18n[languageSlug].readPost,
									href: uri,
								}}
								image={{
									...(!isGifImage && {
										fluid: featuredImageSources,
									}),
									...(isGifImage && {
										src: featuredImage.sourceUrl,
										type: 'gif',
									}),
								}}
								title={title}
								// uptitle={primary_category.name}
								text={excerptTxt}
								author={{
									image:
										author.customAvatar?.image?.imageFile
											?.childImageSharp,
									name: author.name,
									date: formatDate(languageSlug, date),
									category: categoryName,
									// profileLink: '/todo',
									// categoryLink: '/todo',
								}}
							/>
						);
					}
				)}

				{numPages > 1 ? (
					<nav className="blog-pagination col-default">
						{hasPreviousPage ? (
							<Link
								className="blog-pagination__previous"
								to={`${blogPath}/${currentPage - 1}`}
							>
								{i18n[languageSlug].previous}
							</Link>
						) : (
							<span></span>
						)}
						<ul className="blog-pagination__pages">
							{Array.from({ length: numPages }, (_, i) => (
								<li key={`pagination-number${i + 1}`}>
									<Link
										to={`${blogPath}/${
											i === 0 ? '' : i + 1
										}`}
									>
										{i + 1}
									</Link>
								</li>
							))}
						</ul>
						{hasNextPage ? (
							<Link
								className="blog-pagination__next"
								to={`${blogPath}/${currentPage + 1}`}
							>
								{i18n[languageSlug].next}
							</Link>
						) : (
							<span></span>
						)}
					</nav>
				) : null}
			</section>
		</Layout>
	);
};

export default BlogPage;

export const query = graphql`
	query(
		$first: Int
		$after: String
		$language: WPGraphQL_LanguageCodeFilterEnum
	) {
		wpgraphql {
			posts(
				first: $first
				after: $after
				where: { parent: null, language: $language }
			) {
				edges {
					cursor
					node {
						id
						title
						uri
						excerpt(format: RENDERED)
						date
						categories {
							nodes {
								databaseId
								uri
								name
							}
						}
						...CardArticleImageFragment
						...AuthorFragment
					}
				}
			}
		}
	}
`;
