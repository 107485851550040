import '../card.scss';
import './styles.scss';

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { graphql } from 'gatsby';

import Author from '@components/molecules/Author';
import ButtonSecondary from '@components/atoms/buttons/ButtonSecondary';
import Image from '@components/atoms/Image';

const CardArticle = ({
	modifiers,
	className,
	link,
	image,
	title,
	uptitle,
	text,
	author,
	...otherProps
}) => {
	const baseClass = 'card-article';
	const defaultClass = 'card';

	const rootClass = cx(baseClass, className, defaultClass, {
		[`${baseClass}--${modifiers}`]: modifiers,
	});

	return (
		<article className={rootClass} data-card data-target {...otherProps}>
			<figure className="card__thumbnail" aria-label={title}>
				<Image className="card__img" {...image} />
				<span className="card__shadow"></span>
			</figure>
			<div className="card__author">
				<Author
					modifiers="card"
					image={author.image}
					name={author.name}
					date={author.date}
					category={author.category}
					categoryLink={author.categoryLink}
					profileLink={author.profileLink}
				/>
			</div>
			<div className="card__info">
				{uptitle && <p className="card__category uptitle">{uptitle}</p>}
				<h2
					className="card__title"
					dangerouslySetInnerHTML={{
						__html: title,
					}}
				/>
				<p
					className="card__text"
					dangerouslySetInnerHTML={{
						__html: text,
					}}
				/>
				<ButtonSecondary href={link.href} text={link.title} />
			</div>
		</article>
	);
};

CardArticle.propTypes = {
	modifiers: PropTypes.string,
	className: PropTypes.string,
	link: PropTypes.object.isRequired,
	image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
	title: PropTypes.string.isRequired,
	uptitle: PropTypes.string,
	text: PropTypes.string.isRequired,
	author: PropTypes.object.isRequired,
};

export default CardArticle;

export const query = graphql`
	fragment CardArticleImageFragment on WPGraphQL_Post {
		featuredImage {
			sourceUrl # necessary for imageFile resolver to work
			mimeType
			mobile: imageFile {
				publicURL
				childImageSharp {
					# TODO: define sizes?
					fluid(
						maxWidth: 342
						maxHeight: 192
						cropFocus: CENTER
						quality: 95
					) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
			desktop: imageFile {
				publicURL
				childImageSharp {
					# TODO: define sizes?
					fluid(
						maxWidth: 992
						maxHeight: 416
						cropFocus: CENTER
						quality: 95
					) {
						...GatsbyImageSharpFluid_withWebp_noBase64
					}
				}
			}
		}
	}
`;
